import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faArrowDown,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

const SummaryCard = ({
  icon,
  title,
  value,
  percentage,
  trendColor,
  description,
}) => {
  return (
    <div className='d-flex align-items-center p-3'>
      <div
        className='d-flex justify-content-center align-items-center rounded-circle bg-info-subtle p-5'
        style={{ width: '50px', height: '50px' }}
      >
        <FontAwesomeIcon icon={icon} size='2x' className='text-info' />
      </div>
      <div className='ms-3'>
        <h6 className='mb-0'>{title}</h6>
        <h2 className='mb-1'>{value}</h2>
        <span className={`small ${trendColor}`}>
          <FontAwesomeIcon icon={percentage > 0 ? faArrowUp : faArrowDown} />{' '}
          {Math.abs(percentage)}%
        </span>
        <span className='small text-muted px-3'>{description}</span>
      </div>
    </div>
  );
};

export default SummaryCard;
