import React from 'react';
import { Card, Button } from 'react-bootstrap';

function DynamicCard({ title, text, imgUrl, buttonText, buttonUrl }) {
  return (
    <Card style={{ width: '18rem' }}>
      {/* <Card.Img variant='top' src={imgUrl} alt='Card image' />*/}
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{text}</Card.Text>
        <Button variant='primary' href={buttonUrl}>
          {buttonText}
        </Button>
      </Card.Body>
    </Card>
  );
}

export default DynamicCard;
