import React, { useState } from 'react';
import { Button, Container, Row, Col, Accordion, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import CustomPagination from '../util/CustomPagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleDown,
  faChevronCircleUp,
} from '@fortawesome/free-solid-svg-icons';

import {
  faHome,
  faBars,
  faTasks,
  faFile,
  faSearch,
  faArrowUp,
  faArrowDown,
  faArrowsUpDown,
  faBullseye,
} from '@fortawesome/free-solid-svg-icons';

// Example dynamic data
const actionItemsData = [
  {
    orderId: '#289182 H&M Style 3829',
    rows: [
      {
        date: '27 Jun',
        action: 'Give details of order to the Supplier',
        contact: 'Supplier@gmail.com',
        status: 'Pending',
        remarks: 'You are yet to reply',
        takeAction: 'Action',
      },
      {
        date: '23 Jun',
        action: 'Seek Buyer feedback on Sample',
        contact: 'Buyer@gmail.com',
        status: 'Pending',
        remarks: 'You are yet to send a reminder',
        takeAction: 'Action',
      },
      {
        date: '10 Jun',
        action: 'Give confirmation about Sample Approval for Dispatch',
        contact: 'Sample1@gmail.com',
        status: 'Done',
        remarks: 'You approved the sample on 12 Jun',
        takeAction: 'Action',
      },
    ],
  },
  {
    orderId: '#43098 ZARA Style SS2024/2',
    rows: [
      {
        date: '27 Jun',
        action: 'Logistics team asks your shipping plan',
        contact: 'Logistics@gmail.com',
        status: 'Pending',
        remarks: 'You are yet to reply',
        takeAction: 'Action',
      },
      {
        date: '23 Jun',
        action: 'Seek Buyer feedback on Washing',
        contact: 'Buyer@gmail.com',
        status: 'Pending',
        remarks: 'You are yet to send a reminder',
        takeAction: 'Action',
      },
      {
        date: '22 Jun',
        action:
          'Quality department noticed defect in trims. Asked for deviation',
        contact: 'quality@gmail.com',
        status: 'Done',
        remarks: 'You approved on 22 Jun',
        takeAction: 'Action',
      },
      {
        date: '20 Jun',
        action: 'Washing asked for your approval on deviation',
        contact: 'Washing@gmail.com',
        status: 'Done',
        remarks: 'You asked for hold and asked buyer approval',
        takeAction: 'Action',
      },
    ],
  },
];

// Component for the Action Items table
const ActionItemsTable = ({ orderId, rows, eventKey, isOpen }) => {
  const customSortIcon = type => {
    switch (type) {
      case 'asc':
        return <FontAwesomeIcon icon={faArrowUp} className='px-2' />;
      case 'desc':
        return <FontAwesomeIcon icon={faArrowDown} className='px-2' />;
      default:
        return <FontAwesomeIcon icon={faArrowsUpDown} className='px-2' />;
    }
  };
  const columns = [
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
    },
    {
      name: 'Action',
      selector: row => row.action,
      sortable: true,
    },
    {
      name: 'Point of Contact',
      selector: row => row.contact,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      cell: row => (
        <span
          className={`badge w-75 ${
            row.status === 'Pending' ? 'bg-warning' : 'bg-success'
          }`}
        >
          {row.status}
        </span>
      ),
    },
    {
      name: 'Remarks',
      selector: row => row.remarks,
      sortable: true,
    },
    {
      name: 'Take Action',
      selector: row => row.takeAction,
      sortable: true,
      cell: () => (
        <Button variant='outline-success' size='sm'>
          Action
        </Button>
      ),
    },
  ];

  return (
    <Card className='my-3'>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>
          {/* <FontAwesomeIcon
            icon={isOpen ? faChevronCircleUp : faChevronCircleDown}
            className='ms-2'
          /> */}
          <h5 className='mb-0'>{orderId}</h5>
        </Accordion.Header>
        <Accordion.Body>
          <DataTable
            columns={columns}
            data={rows}
            noHeader
            pagination
            paginationPerPage={5}
            paginationComponent={CustomPagination}
            className='data-table border'
            fixedHeader
            fixedHeaderScrollHeight='400px'
            sortIcon={customSortIcon('asc')}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Card>
  );
};

const ActionItemsView = () => {
  const [activeKey, setActiveKey] = useState('0'); // To keep the first accordion open by default

  return (
    <Container fluid className='py-3 px-0'>
      <Card>
        <Card.Body>
          {/* Title Section */}
          <Row className='justify-content-center mb-4'>
            <Col>
              <h5 className='text-center text-primary'>Action Items</h5>
            </Col>
          </Row>

          {/* Dynamic accordion for each action item */}
          <Accordion activeKey={activeKey} onSelect={key => setActiveKey(key)}>
            {actionItemsData.map((item, index) => (
              <ActionItemsTable
                key={index}
                orderId={item.orderId}
                rows={item.rows}
                eventKey={index.toString()}
                isOpen={activeKey === index.toString()}
              />
            ))}
          </Accordion>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ActionItemsView;
