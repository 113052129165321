import React from 'react';
import DynamicCard from '../util/DynamicCard';

const File = () => {
  return (
    <>
      <DynamicCard
        title='File Card'
        text='This is the first dynamic card.'
        imgUrl='https://via.placeholder.com/150'
        buttonText='Learn More'
        buttonUrl='https://example.com'
      />
    </>
  );
};

export default File;
