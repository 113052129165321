import React from 'react';
import { Button } from 'react-bootstrap';

const CustomPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage,
}) => {
  const totalPages = Math.ceil(rowCount / rowsPerPage);
  const pageButtons = [];

  for (let i = 1; i <= totalPages; i++) {
    if (
      i === 1 ||
      i === totalPages ||
      i === currentPage ||
      i === currentPage - 1 ||
      i === currentPage + 1
    ) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => onChangePage(i)}
          className={`btn btn-sm mx-1 ${
            i === currentPage
              ? 'btn-primary text-white'
              : 'btn-link text-primary'
          }`}
        >
          {i}
        </button>
      );
    } else if (i === currentPage - 2 || i === currentPage + 2) {
      pageButtons.push(<span key={i}>...</span>);
    }
  }

  return (
    <div className='d-flex justify-content-end align-items-center py-2 my-2 col-sm-2 offset-10'>
      <Button
        onClick={() => onChangePage(currentPage - 1)}
        disabled={currentPage === 1}
        className='text-primary mx-2 mb-2'
        style={{
          cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
          border: 'none',
          background: 'none',
        }}
      >
        &lt;
      </Button>
      {pageButtons}
      <Button
        onClick={() => onChangePage(currentPage + 1)}
        disabled={currentPage === totalPages}
        className='text-primary mx-2'
        style={{
          cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
          border: 'none',
          background: 'none',
          color: '#007bff',
          marginLeft: '10px',
        }}
      >
        &gt;
      </Button>
    </div>
  );
};

export default CustomPagination;
