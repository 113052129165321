import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faTasks,
  faFile,
  faBullseye,
  faSignOutAlt, // Replaced faHome with faSignOutAlt for sign-out icon
  faChalkboard,
} from '@fortawesome/free-solid-svg-icons';
import './MainLayout.css'; // Import the CSS file
import { Button, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function MainLayout({ setIsAuthenticated }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    setIsAuthenticated(false); // Set authentication state to false
    navigate('/'); // Redirect to login page after logout
  };

  return (
    <div className='main-container bg-secondary-subtle'>
      {/* Fixed Sidebar */}
      <div className='sidebar'>
        <div className='d-flex align-items-center mb-4'>
          <FontAwesomeIcon icon={faChalkboard} className='me-2' />
          <span>Logo</span>
        </div>
        <div className='flex-grow-1'>
          {/* Navigation links */}
          <NavLink
            to='/'
            className={({ isActive }) =>
              isActive
                ? 'text-light text-start w-100 mb-2 active'
                : 'text-light text-start w-100 mb-2'
            }
          >
            <Button
              variant='link'
              className='text-light text-start w-100 link-underline link-underline-opacity-0'
            >
              <FontAwesomeIcon icon={faHome} className='me-2' /> Home
            </Button>
          </NavLink>

          <NavLink
            to='target'
            className={({ isActive }) =>
              isActive
                ? 'text-light text-start w-100 mb-2 active'
                : 'text-light text-start w-100 mb-2'
            }
          >
            <Button
              variant='link'
              className='text-light text-start w-100 link-underline link-underline-opacity-0'
            >
              <FontAwesomeIcon icon={faBullseye} className='me-2' /> Target
            </Button>
          </NavLink>

          <NavLink
            to='file'
            className={({ isActive }) =>
              isActive
                ? 'text-light text-start w-100 mb-2 active'
                : 'text-light text-start w-100 mb-2'
            }
          >
            <Button
              variant='link'
              className='text-light text-start w-100 link-underline link-underline-opacity-0'
            >
              <FontAwesomeIcon icon={faFile} className='me-2' /> File
            </Button>
          </NavLink>
        </div>

        {/* Logout Button and Profile Section */}
        <div className='d-flex flex-column align-items-center mt-auto'>
          <Button
            onClick={handleLogout}
            variant='link'
            className='text-light text-start w-100 link-underline link-underline-opacity-0 mb-2'
          >
            <FontAwesomeIcon icon={faSignOutAlt} className='me-2' /> Sign Out
          </Button>

          <div className='d-flex align-items-center'>
            <img
              src='https://via.placeholder.com/40'
              alt='profile'
              className='rounded-circle mb-0 bg-light'
              style={{ marginRight: '10px' }}
            />
            <p className='mb-0 mt-2'>Merchandiser</p>
          </div>
        </div>
      </div>

      {/* Content Area */}
      <div className='content'>
        <Outlet /> {/* Dynamic right-side content */}
      </div>
    </div>
  );
}

export default MainLayout;
