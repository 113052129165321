import React from 'react';
import OrderRunningStatusView from './OrderRunningStatusView';
import ActionItemsView from './ActionItemsView';

const Action = () => {
  return (
    <>
      <OrderRunningStatusView />
      <ActionItemsView />
    </>
  );
};

export default Action;
