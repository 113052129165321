import React, { useState } from 'react';
import { Button, Card, Form, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

/*
# TODO
//just in case API is used
/* // Simulated API call using JSONPlaceholder
const fetchUsers = async () => {
  const response = await fetch('https://jsonplaceholder.typicode.com/users');
  const data = await response.json();
  return data;
}; */

// Sample user for simulation
const sampleUser = {
  email: 'testuser@example.com',
  password: 'password123',
};

const AuthScreen = ({ setIsAuthenticated }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const toggleLoginSignup = () => {
    setIsLogin(!isLogin);
    setError('');
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().when('isLogin', {
      is: false,
      then: Yup.string().required('Name is required'),
    }),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    setError('');

    if (isLogin) {
      /*
      # TODO
       //Just incase API is used
      try {
        const users = await fetchUsers();
        const user = users.find(user => user.email === values.email);
        if (user) {
          setIsAuthenticated(true); // set authentication to true
          navigate('/dashboard'); // redirect to dashboard
        } else {
          setError('Invalid email or password');
        }
      } catch (error) {
        setError('Failed to fetch users. Please try again later.');
      } finally {
        setSubmitting(false);
      }
      */

      // Simulate login by checking the email and password
      if (
        values.email === sampleUser.email &&
        values.password === sampleUser.password
      ) {
        setIsAuthenticated(true); // set authentication to true
        navigate('/dashboard'); // redirect to dashboard
      } else {
        setError('Invalid email or password');
      }
      setSubmitting(false);
    } else {
      // Simulate signup logic (mock)
      setIsAuthenticated(true);
      navigate('/dashboard');
      setSubmitting(false);
    }
  };
  //     border-top: 5px solid black;    border-radius: 0px;
  return (
    <Container className='d-flex justify-content-center align-items-center vh-100'>
      <Card
        style={{ width: '25rem', borderTop: '5px solid black' }}
        className='p-4 rounded-0 shadow'
      >
        <div className='text-center mb-4'>
          <FontAwesomeIcon icon={faUserCircle} size='4x' />
          <h3>{isLogin ? 'Login' : 'Sign Up'}</h3>
        </div>

        <Formik
          initialValues={{ name: '', email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {!isLogin && (
                <Form.Group controlId='formBasicName'>
                  <Form.Label className='fw-bold mb-0'>Name</Form.Label>
                  <Field
                    name='name'
                    type='text'
                    className='form-control border-secondary-subtle'
                    placeholder='Enter your name'
                  />
                  <ErrorMessage
                    name='name'
                    component='div'
                    className='text-danger'
                  />
                </Form.Group>
              )}
              <Form.Group controlId='formBasicEmail' className='mt-3'>
                <Form.Label className='fw-bold mb-0'>Email</Form.Label>
                <Field
                  name='email'
                  type='email'
                  className='form-control border-secondary-subtle'
                  placeholder='Enter email'
                />
                <ErrorMessage
                  name='email'
                  component='div'
                  className='text-danger'
                />
              </Form.Group>
              <Form.Group controlId='formBasicPassword' className='mt-3'>
                <Form.Label className='fw-bold mb-0'>Password</Form.Label>
                <Field
                  name='password'
                  type='password'
                  className='form-control border-secondary-subtle'
                  placeholder='Password'
                />
                <ErrorMessage
                  name='password'
                  component='div'
                  className='text-danger'
                />
              </Form.Group>
              {error && <div className='text-danger mt-2'>{error}</div>}
              <Button
                variant='primary'
                type='submit'
                className='w-100 mt-3 fw-bold'
                disabled={isSubmitting}
              >
                {isLogin ? 'Login' : 'Sign Up'}
              </Button>
            </Form>
          )}
        </Formik>

        <div className='mt-3'>
          <Button
            variant='link'
            className='text-decoration-none text-end'
            onClick={toggleLoginSignup}
          >
            {isLogin ? 'Create an account' : 'Already have an account? Login'}
            <FontAwesomeIcon
              icon={faArrowRight}
              size='1x'
              className='px-2 pt-2'
            />
          </Button>
        </div>
      </Card>
    </Container>
  );
};

export default AuthScreen;
