import React from 'react';
import SummaryCard from './SummaryCard';
import { Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import {
  faUsers,
  faUserPlus,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';
const OrderSummary = () => {
  // Array to store the summary data
  const summaryData = [
    {
      title: 'Total Order',
      value: 300,
      icon: faUsers,
      percentage: 16, // <-- percentage as a number
      trendColor: 'text-success',
      description: 'this month',
    },
    {
      title: 'New Order',
      value: 10,
      icon: faUserPlus,
      percentage: -1, // <-- percentage as a number
      trendColor: 'text-danger',
      description: 'this month',
    },
    {
      title: 'Confirmed Order',
      value: 30,
      icon: faUserCheck,
      percentage: 4, // <-- percentage as a number
      trendColor: 'text-success',
      description: 'this month',
    },
  ];

  return (
    <Row className='mb-2 bg-white rounded shadow-sm mx-0'>
      {summaryData.map((item, index) => (
        <Col key={index} md={4}>
          <SummaryCard
            icon={item.icon}
            title={item.title}
            value={item.value}
            percentage={item.percentage}
            trendColor={item.trendColor}
            description={item.description}
          />
        </Col>
      ))}
    </Row>
  );
};

export default OrderSummary;
