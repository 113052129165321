import mockData from './mockData';
import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import OrderSummary from './OrderSummary';
import CustomPagination from '../util/CustomPagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faBars,
  faTasks,
  faFile,
  faSearch,
  faArrowUp,
  faArrowDown,
  faArrowsUpDown,
  faBullseye,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink, useNavigate } from 'react-router-dom';
import '../util/DataTableComponent.css';
const Home = () => {
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState(''); // New state for global search query

  // Fetch mock data when the component mounts
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = async page => {
    try {
      const startIdx = (page - 1) * perPage;
      const endIdx = startIdx + perPage;
      const paginatedData = mockData.slice(startIdx, endIdx);
      setData(paginatedData);
      setTotalRows(mockData.length);
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  // Handle global search input
  const handleSearchChange = e => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterData(query);
  };

  // Global filter function to search across all fields of a row
  const filterData = query => {
    const filteredData = mockData.filter(item =>
      Object.keys(item).some(key =>
        item[key].toString().toLowerCase().includes(query)
      )
    );
    setData(filteredData);
  };

  const nav = useNavigate();
  const handleAction = () => {
    nav('action');
  };
  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      cell: row => (
        <span
          style={{
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'none',
          }}
          onClick={handleAction}
        >
          {row.name}
        </span>
      ),
    },
    {
      name: 'ID Number',
      selector: row => row.idNumber,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Duration',
      selector: row => row.duration,
      sortable: true,
    },
    {
      name: 'Location',
      selector: row => row.location,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      cell: row => (
        <span className={`badge w-75 ${getStatusBadge(row.status)}`}>
          {row.status}
        </span>
      ),
    },
  ];

  const getStatusBadge = status => {
    switch (status) {
      case 'Confirmed Order':
        return 'bg-success text-light';
      case 'New Order':
        return 'bg-warning text-dark';
      case 'Major Issue':
        return 'bg-danger text-light';
      case 'Running Order':
        return 'bg-primary text-light';
      case 'Future Order':
        return 'bg-secondary text-light';
      default:
        return '';
    }
  };

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const customSortIcon = type => {
    switch (type) {
      case 'asc':
        return <FontAwesomeIcon icon={faArrowUp} className='px-2' />;
      case 'desc':
        return <FontAwesomeIcon icon={faArrowDown} className='px-2' />;
      default:
        return <FontAwesomeIcon icon={faArrowsUpDown} className='px-2' />;
    }
  };

  return (
    <>
      {/* Content Area */}
      <div className='flex-grow-1 px-0 '>
        {/* Summary Section */}
        <OrderSummary />

        {/* Table Section */}
        <div className='bg-white p-4 rounded shadow-sm mx-0'>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <h4>All Orders</h4>
            <div className='d-flex'>
              <Form.Control
                type='text'
                placeholder='Search'
                className=''
                value={searchQuery}
                onChange={handleSearchChange} // Global search input handler
              />
              <Button variant='secondary' className='col-sm-2 px-2'>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </div>
          </div>

          <DataTable
            columns={columns}
            data={data} // Data now reflects global search results
            noHeader
            pagination
            paginationPerPage={5}
            paginationComponent={CustomPagination}
            className='data-table border'
            fixedHeader
            fixedHeaderScrollHeight='400px'
            sortIcon={customSortIcon('asc')}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
