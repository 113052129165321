const mockData = [
  {
    id: 1,
    name: 'Jane Cooper',
    email: 'jane@gmail.com',
    idNumber: '0001',
    duration: '24 week',
    location: 'United States',
    status: 'Confirmed Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 2,
    name: 'John Doe',
    email: 'john@gmail.com',
    idNumber: '0002',
    duration: '12 week',
    location: 'Canada',
    status: 'New Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 3,
    name: 'Alice Johnson',
    email: 'alice@gmail.com',
    idNumber: '0003',
    duration: '16 week',
    location: 'United Kingdom',
    status: 'Running Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 4,
    name: 'Robert Smith',
    email: 'robert@gmail.com',
    idNumber: '0004',
    duration: '20 week',
    location: 'Germany',
    status: 'Major Issue',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 5,
    name: 'Emma Wilson',
    email: 'emma@gmail.com',
    idNumber: '0005',
    duration: '18 week',
    location: 'Australia',
    status: 'New Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 6,
    name: 'Michael Brown',
    email: 'michael@gmail.com',
    idNumber: '0006',
    duration: '22 week',
    location: 'France',
    status: 'Confirmed Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 7,
    name: 'Sophia Davis',
    email: 'sophia@gmail.com',
    idNumber: '0007',
    duration: '10 week',
    location: 'United States',
    status: 'Future Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 8,
    name: 'Liam Harris',
    email: 'liam@gmail.com',
    idNumber: '0008',
    duration: '14 week',
    location: 'Spain',
    status: 'Running Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 9,
    name: 'Isabella Clark',
    email: 'isabella@gmail.com',
    idNumber: '0009',
    duration: '12 week',
    location: 'Italy',
    status: 'Confirmed Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 10,
    name: 'James Lewis',
    email: 'james@gmail.com',
    idNumber: '0010',
    duration: '26 week',
    location: 'Brazil',
    status: 'New Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 11,
    name: 'Mia Martinez',
    email: 'mia@gmail.com',
    idNumber: '0011',
    duration: '15 week',
    location: 'Mexico',
    status: 'Major Issue',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 12,
    name: 'William Thompson',
    email: 'william@gmail.com',
    idNumber: '0012',
    duration: '13 week',
    location: 'South Africa',
    status: 'Running Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 13,
    name: 'Olivia Garcia',
    email: 'olivia@gmail.com',
    idNumber: '0013',
    duration: '17 week',
    location: 'Netherlands',
    status: 'Confirmed Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 14,
    name: 'Noah Walker',
    email: 'noah@gmail.com',
    idNumber: '0014',
    duration: '21 week',
    location: 'New Zealand',
    status: 'New Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 15,
    name: 'Ava Adams',
    email: 'ava@gmail.com',
    idNumber: '0015',
    duration: '11 week',
    location: 'Japan',
    status: 'Confirmed Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 16,
    name: 'Ethan Baker',
    email: 'ethan@gmail.com',
    idNumber: '0016',
    duration: '20 week',
    location: 'South Korea',
    status: 'Running Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 17,
    name: 'Amelia Wilson',
    email: 'amelia@gmail.com',
    idNumber: '0017',
    duration: '25 week',
    location: 'United States',
    status: 'Future Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 18,
    name: 'Logan Hall',
    email: 'logan@gmail.com',
    idNumber: '0018',
    duration: '19 week',
    location: 'Russia',
    status: 'Major Issue',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 19,
    name: 'Charlotte Young',
    email: 'charlotte@gmail.com',
    idNumber: '0019',
    duration: '23 week',
    location: 'India',
    status: 'Confirmed Order',
    image: 'https://via.placeholder.com/150',
  },
  {
    id: 20,
    name: 'Lucas King',
    email: 'lucas@gmail.com',
    idNumber: '0020',
    duration: '16 week',
    location: 'China',
    status: 'New Order',
    image: 'https://via.placeholder.com/150',
  },
];

export default mockData;
