import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AuthScreen from "./components/auth/AuthScreen";
import MainLayout from "./components/layout/MainLayout";
import Home from "./components/container/home/Home";
import Target from "./components/container/target/Target";
import Action from "./components/container/action/Action";
import File from "./components/container/file/File";
import "bootstrap/dist/css/bootstrap.min.css";

export function App(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <Router className="App">
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/dashboard" />
            ) : (
              <AuthScreen setIsAuthenticated={setIsAuthenticated} />
            )
          }
        />

        {isAuthenticated && (
          <>
            <Route
              path="/dashboard"
              element={<MainLayout setIsAuthenticated={setIsAuthenticated} />}
            >
              <Route index element={<Home />} /> {/* Default is Home */}
              <Route path="target" element={<Target />} />{" "}
              {/* Fix the relative path */}
              <Route path="action" element={<Action />} />{" "}
              {/* Fix the relative path */}
              <Route path="file" element={<File />} />{" "}
              {/* Fix the relative path */}
            </Route>
          </>
        )}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
