import React, { useState, useEffect } from 'react';
import {
  Card,
  Container,
  Row,
  Col,
  Table,
  ProgressBar,
  Button,
} from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

// Dynamic data
const userData = {
  name: 'Steve Jones',
  email: 'steve@gmail.com',
  week: '12 Week',
  trackingId: '#0001',
  progress: 45, // Assuming the progress is dynamic
  image: 'https://via.placeholder.com/70', // Replace with actual image URL if available
};

const milestonesData = [
  {
    step: 'Production Planning',
    date: '20 Dec 2022',
    pointOfContact: 'Process Owner',
    delay: 'NO',
    color: 'green',
  },
  {
    step: 'Cutting',
    date: '22 Dec 2022',
    pointOfContact: 'Process Owner',
    delay: 'YES',
    color: 'red',
  },
  {
    step: 'Sewing',
    date: '20 Dec 2022',
    pointOfContact: 'Process Owner',
    delay: 'NO',
    color: 'green',
  },
  {
    step: 'Washing',
    date: '20 Dec 2022',
    pointOfContact: 'Process Owner',
    delay: 'NO',
    color: 'green',
  },
];

const stages = ['Production', 'Cutting', 'Sewing', 'Washing', 'Add new']; // Stage names for carousel

const OrderTrackingView = () => {
  // State for the active stage in the carousel
  const [activeIndex, setActiveIndex] = useState(0);

  // Scroll automatically every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(prevIndex => (prevIndex + 1) % stages.length);
    }, 3000); // Change the active index every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const handleNext = () => {
    setActiveIndex(prevIndex => (prevIndex + 1) % stages.length);
  };

  const handlePrev = () => {
    setActiveIndex(
      prevIndex => (prevIndex - 1 + stages.length) % stages.length
    );
  };

  // Display 4 stages at a time
  const visibleStages = stages
    .slice(activeIndex, activeIndex + 4)
    .concat(stages.slice(0, Math.max(0, activeIndex + 4 - stages.length)));

  return (
    <Container fluid className='card shadow-sm p-4'>
      {/* Tracking ID */}
      <Row className='justify-content-center mb-0'>
        <h2 className='text-center text-primary'>
          Tracking ID: {userData.trackingId}
        </h2>
      </Row>

      {/* Progress Bar Section (Carousel-like) */}
      <Row className='justify-content-around mb-4 py-0 px-0'>
        <Col xs={12}>
          <div className='carousel-container d-flex justify-content-around align-items-center  rounded bg-secondary-subtle border py-3 px-0'>
            {/* Previous button */}
            <FaChevronLeft
              className='carousel-control-prev-icon'
              role='button'
              aria-label='Previous'
              onClick={handlePrev}
              style={{ cursor: 'pointer' }}
            />

            <div className='d-flex justify-content-around align-items-center mx-5 py-2'>
              {/* Render 4 visible stages */}
              {visibleStages.map((stage, idx) => (
                <div key={idx} className='text-center mx-5'>
                  <div
                    className={`rounded-circle ${
                      idx === activeIndex ? 'bg-success' : 'bg-dark'
                    }`}
                    style={{ width: '40px', height: '40px', margin: '0 auto' }}
                  />
                  <div className='mt-2' style={{ fontSize: '12px' }}>
                    {stage}
                  </div>
                </div>
              ))}
            </div>

            {/* Next button */}
            <FaChevronRight
              className='carousel-control-next-icon'
              role='button'
              aria-label='Next'
              onClick={handleNext}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </Col>
      </Row>

      {/* Profile and Order Status Section */}
      <Row className='justify-content-center mb-4'>
        <Col xs={12} md={4} className='text-center'>
          <Card className='p-3 text-center d-flex align-items-stretch shadow-md border-1'>
            <Card.Img
              variant='top'
              src={userData.image}
              alt='User'
              className='rounded-circle mx-auto'
              style={{ width: '70px', height: '70px' }}
            />
            <Card.Body className='d-flex flex-column'>
              <Card.Title>{userData.name}</Card.Title>
              <Card.Subtitle className='mb-2 text-muted'>
                {userData.email}
              </Card.Subtitle>
              <Button variant='light' disabled className='mt-2'>
                {userData.week}
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={4} className='text-center'>
          <Card className='p-3 text-center d-flex align-items-stretch shadow-md border-1'>
            <Card.Body className='d-flex flex-column justify-content-center'>
              <h4 className='my-4'>Order Status</h4>
              <h2>{userData.progress}%</h2>
              <ProgressBar now={userData.progress} className='my-3' />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Milestones Section */}
      <Row className='bg-light py-3 px-0'>
        <Col xs={12}>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th className='bg-secondary-subtle text-dark'>Milestones</th>
                <th className='bg-secondary-subtle text-dark'>Action Trails</th>
                <th className='bg-secondary-subtle text-dark'>
                  Point of Contact
                </th>
                <th className='bg-secondary-subtle text-dark'>Delays</th>
              </tr>
            </thead>
            <tbody>
              {milestonesData.map((milestone, index) => (
                <tr key={index}>
                  <td>{milestone.step}</td>
                  <td>{milestone.date}</td>
                  <td>{milestone.pointOfContact}</td>
                  <td>
                    <span
                      className={`badge bg-${
                        milestone.delay === 'YES' ? 'danger' : 'success'
                      }`}
                    >
                      {milestone.delay}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderTrackingView;
